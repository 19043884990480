<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-people"/> Subcontractors
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                clickableRows
                @row-clicked="rowClicked"
                column-filter
                :items="subcontractors"
                :fields="subcontractorsFields"
                head-color="light"
                :loading="isLoading"
            >
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import Service from '../../../services/service';
import AuthService from '../../../services/auth.service';
import {AuthEnum} from '@caocao-mobility/ccmp-enum';
import _c from 'lodash/collection'

export default {
  name: 'Subcontractors',
  created() {
    if (AuthService.user.type === AuthEnum.Type.LEASER_USER) {
      axios.get(`/subcontractorId`).then((response) => {
        if (response.data.labour_no) {
          this.$router.push(`/operation/subcontractors/${response.data.labour_no}`);
        }
      })
    } else {
      // GET all subcontractors
      axios.get(`/subcontractors`).then((response) => {
        this.isLoading = false

        response.data = _c.orderBy(response.data, ['legal_name', 'labour_no'], ['asc', 'asc']);

        // TODO: Replace with ccmp-enum
        this.subcontractors = _c.filter(response.data, (customers) => customers["operate_flag"] === 3);

      }).catch((error) => {
        console.log(error)
        this.isLoading = false
      })
    }
  },
  data () {
    return {
      isLoading: true,
      subcontractors: [],
      subcontractorsFields: [
        { key: 'labour_no', _style: 'width:150px' },
        { key: 'legal_name' }
      ]
    }
  },
  methods: {
    rowClicked(subcontractor) {
      this.$router.push(`/operation/subcontractors/${subcontractor.labour_no}`);
    }
  }
}
</script>
