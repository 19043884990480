<template>
  <div>
    <CRow v-if="!isLoading">
      <CCol col="12 mb-4">
        <h1>{{this.driver.name}} {{this.driver.surname}}</h1>
      </CCol>
    </CRow>
    <CRow v-if="!isLoading">
      <CCol col="12" lg="3">
        <CWidgetDropdown
            :header="this.driver.stats.gmv_77 | convertCentsToEurosWithCurrency"
            text="Chiffre d'affaires net"
            color="primary">

          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="[65, 59, 84, 84, 51, 55, 40]"
              point-hover-background-color="primary"
              label=""
              labels=""
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
            :header="this.driver.stats.gmv_77_avg | convertCentsToEurosWithCurrency"
            text="Revenu net moyen d'une course"
            color="gradient-info">
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="[1, 18, 9, 17, 34, 22, 11]"
              point-hover-background-color="info"
              :options="{ elements: { line: { tension: 0.00001 }}}"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
            :header="(this.driver.stats.total_mileage ? this.driver.stats.total_mileage : '-') + ' km'"
            text="Kms parcourus en course"
            color="gradient-warning">
          <template #footer>
            <CChartLineSimple
              class="mt-3"
              style="height:70px"
              background-color="rgba(255,255,255,.2)"
              :data-points="[78, 81, 80, 45, 34, 12, 40]"
              :options="{ elements: { line: { borderWidth: 2.5 }}}"
              point-hover-background-color="warning"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
            :header="(this.driver.stats.evaluate_score ? this.driver.stats.evaluate_score.toFixed(1) : '-') + '/5'"
            text="Note du jour"
            color="gradient-danger">
          <template #footer>
            <CChartBarSimple
              class="mt-3 mx-3"
              style="height:70px"
              background-color="rgb(250, 152, 152)"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>
    <CRow v-if="!isLoading">
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Traffic
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="warning">
                      <small class="text-muted">Nombre de courses proposées (instant ride) <sup>1</sup></small><br>
                      <strong class="h4">{{this.driver.stats.nb_proposed_instant_ride}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'acceptation courses immediates</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.stats.nb_accepted_instant_ride}} ({{ this.driver.stats.accepted_instant_ride_rate.toFixed() }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.stats.accepted_instant_ride_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'annulation par le chauffeur</span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.stats.nb_driver_cancel }} ({{ this.driver.stats.driver_cancel_rate.toFixed() }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.stats.driver_cancel_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Nombre de courses annulées chauffeur</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.stats.nb_driver_cancel}}</span>
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Nombre de courses annulées (client, system, support)</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.stats.nb_cancel - this.driver.stats.nb_driver_cancel}}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="danger">
                      <small class="text-muted">Nombre de courses effectuées (instant - booked - 4h 8h) <sup>2</sup></small><br>
                      <strong class="h4">{{driver.stats.nb_ride_total}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Courses immediates <sup>2</sup></span>
                      <span class="ml-auto font-weight-bold">{{driver.stats.nb_instant_ride_done}}</span>
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Réservations</span>
                      <span class="ml-auto font-weight-bold">{{driver.stats.nb_prebooked_ride_done}}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="success">
                      <small class="text-muted">Temps de connexion</small><br>
                      <strong class="h4">{{this.driver.stats.time_online + this.driver.stats.time_in_ride | secondToTime }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Temps en course</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.stats.time_in_ride | secondToTime }}</span>
                    </div>
                  </div>
                </ul>
              </CCol>
            </CRow>

            <p>1 - Le nombre de courses immediates proposées aujourd'hui</p>
            <p>2 - Le nombre de courses terminées aujourd'hui (peuvent avoir été commencées hier)</p>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <slot name="loading" v-if="isLoading">
      <CElementCover
        :boundaries="[
            { sides: ['top'] },
            { sides: ['bottom'] }
          ]"
      />
    </slot>
  </div>
</template>

<script>
import axios from 'axios';
import { CChartLineSimple, CChartBarSimple } from '../../charts'
import AuthService from '@/services/auth.service';

export default {
  name: 'Driver',
  components: {
    CChartLineSimple,
    CChartBarSimple
  },
  created() {

    // GET driver
    axios.get(`/subcontractors/${this.$route.params.subcontractorId}/drivers/${this.$route.params.driverId}`).then((response) => {
      this.isLoading = false
      this.driver = response.data

      this.driver.stats.driver_cancel_rate = (this.driver.stats.nb_driver_cancel * 100) / this.driver.stats.nb_proposed_ride;
      this.driver.stats.driver_cancel_rate = this.driver.stats.driver_cancel_rate ? this.driver.stats.driver_cancel_rate : 0;
      this.driver.stats.accepted_instant_ride_rate = (this.driver.stats.nb_accepted_instant_ride * 100) / this.driver.stats.nb_proposed_instant_ride;
      this.driver.stats.accepted_instant_ride_rate = this.driver.stats.accepted_instant_ride_rate ? this.driver.stats.accepted_instant_ride_rate : 0;
    }).catch((error) => {
      if (error.response.status === 403) {
        AuthService.logout();
      }
    });
  },
  data () {
    return {
      isLoading: true,
      driver: {},
    }
  },
  methods: {

  }
}
</script>
