<template>
  <div>
    <CRow v-if="!isLoading">
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.stats.gmv_77 | convertCentsToEurosWithCurrency"
          text="Chiffre d'affaires net"
          color="primary">

          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="[65, 59, 84, 84, 51, 55, 40]"
              point-hover-background-color="primary"
              label=""
              labels=""
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.stats.gmv_77_avg | convertCentsToEurosWithCurrency"
          text="Revenu net moyen d'une course"
          color="gradient-info">
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="[1, 18, 9, 17, 34, 22, 11]"
              point-hover-background-color="info"
              :options="{ elements: { line: { tension: 0.00001 }}}"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="(this.stats.total_mileage ? this.stats.total_mileage : '-') + ' km'"
          text="Kms parcourus en course"
          color="gradient-warning">
          <template #footer>
            <CChartLineSimple
              class="mt-3"
              style="height:70px"
              background-color="rgba(255,255,255,.2)"
              :data-points="[78, 81, 80, 45, 34, 12, 40]"
              :options="{ elements: { line: { borderWidth: 2.5 }}}"
              point-hover-background-color="warning"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="(this.stats.evaluate_score ? this.stats.evaluate_score.toFixed(1) : '-') + '/5'"
          text="Note du jour"
          color="gradient-danger">
          <template #footer>
            <CChartBarSimple
              class="mt-3 mx-3"
              style="height:70px"
              background-color="rgb(250, 152, 152)"
              label="Members"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>
    <CRow v-if="!isLoading">
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Traffic
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="warning">
                      <small class="text-muted">Nombre de courses proposées (instant ride) <sup>1</sup></small><br>
                      <strong class="h4">{{this.stats.nb_proposed_instant_ride}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'acceptation courses immediate</span>
                      <span class="ml-auto font-weight-bold">{{this.stats.nb_accepted_instant_ride}} ({{ this.stats.accepted_instant_ride_rate.toFixed() }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.stats.accepted_instant_ride_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'annulation par le chauffeur</span>
                      <span class="ml-auto font-weight-bold">{{ this.stats.nb_driver_cancel }} ({{ this.stats.driver_cancel_rate.toFixed() }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.stats.driver_cancel_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Nombre de courses annulées chauffeur</span>
                      <span class="ml-auto font-weight-bold">{{this.stats.nb_driver_cancel}}</span>
                    </div>
                  </div>
                  <div class="progress-group mb-5">
                    <div class="progress-group-header">
                      <span class="title">Nombre de courses annulées (client, system, support)</span>
                      <span class="ml-auto font-weight-bold">{{this.stats.nb_cancel - this.stats.nb_driver_cancel}}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="danger">
                      <small class="text-muted">Nombre de courses effectuées (instant - booked - 4h 8h) <sup>2</sup></small><br>
                      <strong class="h4">{{this.stats.nb_ride_total}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Course immediates <sup>2</sup></span>
                      <span class="ml-auto font-weight-bold">{{this.stats.nb_instant_ride_done}}</span>
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Réservations</span>
                      <span class="ml-auto font-weight-bold">{{this.stats.nb_prebooked_ride_done}}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="success">
                      <small class="text-muted">Temps de connexion</small><br>
                      <strong class="h4">{{this.stats.time_online + this.stats.time_in_ride | secondToTime }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Temps en course</span>
                      <span class="ml-auto font-weight-bold">{{this.stats.time_in_ride | secondToTime }}</span>
                    </div>
                  </div>
                </ul>
              </CCol>
            </CRow>

            <p>1 - Le nombre de courses immediates proposées aujourd'hui</p>
            <p>2 - Le nombre de courses terminées aujourd'hui (peuvent avoir été commencées hier)</p>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-if="!isLoading">
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-people"/> Chauffeurs
            </slot>
          </CCardHeader>
          <CCardBody>

            <CRow>
              <CCol col="12" md="3">
                <CCallout color="info">
                  <small class="text-muted">Connectés</small><br>
                  <strong class="h4">{{ this.nbDriverConnected }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="warning">
                  <small class="text-muted">En course</small><br>
                  <strong class="h4">{{ this.nbDriverInService }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="primary">
                  <small class="text-muted">Disponible</small><br>
                  <strong class="h4">{{ this.nbDriverAvailable }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="secondary">
                  <small class="text-muted">En réservation unique</small><br>
                  <strong class="h4">{{ this.nbDriverInBookedOnly }}</strong>
                </CCallout>
              </CCol>
            </CRow>
            <CDataTable
              class="mb-0 table-outline"
              :items="drivers"
              :fields="driversFields"
              head-color="light"
              hover
              sorter
              column-filter
              clickableRows
              @row-clicked="rowClicked"
            >
              <td slot="avatar" class="text-center" slot-scope="{item}">
                <div class="c-avatar" :set="imgUrl = 'https://ccglobal.oss-eu-central-1.aliyuncs.com/' + item.photo">
                  <img :src="item.photo === 'default.png' ? 'img/avatar_placeholder.png' : imgUrl " class="c-avatar-img" alt="">
                  <span
                    class="c-avatar-status"
                    :class="`bg-${item.status_key || 'secondary'}`"
                  ></span>
                </div>
              </td>
              <td slot="type_order" class="text-center" slot-scope="{item}">
                {{ typeOrderFormat(item.type_order) }}
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-if="!isLoading">
      <CCol md="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 id="traffic" class="card-title mb-0">Historique</h4>
                <div class="small text-muted">Sur les 30 derniers jours</div>
              </CCol>
              <CCol sm="7">
                <DownloadButton
                  v-bind:data="this.history.csvArray"
                  format="csv"
                ></DownloadButton> <DownloadButton v-if="this.history.csvData"
                  v-bind:data="this.history.csvData"
                  v-bind:filename="this.history.csvFilename"
                  format="csv"
                  ></DownloadButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CChartLine
                  style="height:300px"
                  :datasets="this.history.ridesChartValues"
                  :labels="this.history.ridesChartLabels"
                  :options="this.history.ridesChartOptions"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <slot name="loading" v-if="isLoading">
      <CElementCover
        :boundaries="[
            { sides: ['top', 'bottom'], query: '.c-body' }
          ]"
      />
    </slot>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash'
import Service from '../../../services/service';
import AuthService from '../../../services/auth.service';
import {CChartBarSimple, CChartLineSimple} from "@/views/charts";
import DownloadButton from "@/views/buttons/DownloadButton";
import {CChartLine} from "@coreui/vue-chartjs";

export default {
  name: 'Subcontractor',
  components: {
    CChartLineSimple,
    CChartBarSimple,
    CChartLine,
    DownloadButton
  },
  created() {
    // GET drivers
    axios.get(
      `/subcontractors/${this.$route.params.subcontractorId}/drivers?withFigures=true`).then((response) => {
      this.isLoading = false

      // TODO: REPLACE WITH ENUM
      response.data = _.filter(response.data, (driver) => (driver["driver_status"] === 12));

      for(const driver of response.data) {
        this.stats.gmv_77 += driver.stats.gmv_77;
        this.stats.gmv_77_avg += driver.stats.gmv_77_avg;
        this.stats.total_mileage += driver.stats.total_mileage;
        this.stats.evaluate_score += driver.stats.evaluate_score;
        this.stats.nb_ride_total += driver.stats.nb_ride_total;
        this.stats.nb_proposed_ride = driver.stats.nb_proposed_instant_ride + driver.stats.nb_proposed_prebooked_ride
        this.stats.nb_proposed_instant_ride += driver.stats.nb_proposed_instant_ride;
        this.stats.nb_accepted_instant_ride += driver.stats.nb_accepted_instant_ride;
        this.stats.nb_instant_ride_done += driver.stats.nb_instant_ride_done;
        this.stats.nb_cancelled_instant_ride += driver.stats.nb_cancelled_instant_ride;
        this.stats.nb_prebooked_ride_done += driver.stats.nb_prebooked_ride_done;
        this.stats.nb_prebooked_ride_cancelled += driver.stats.nb_prebooked_ride_cancelled;
        this.stats.nb_driver_cancel += driver.stats.nb_driver_cancel;
        this.stats.nb_cancel += driver.stats.nb_cancel;
        this.stats.time_online += driver.stats.time_online;
        this.stats.time_in_ride += driver.stats.time_in_ride;

        driver.nb_ride_total = driver.stats.nb_ride_total
        driver.nb_driver_cancel = driver.stats.nb_driver_cancel
        driver._classes = this.getRowStatusFor(driver.nb_driver_cancel)
        driver.status = driver.online
        driver.status_key = this.getOnlineStatus(driver.online)
        driver.fullName = `${driver.name} ${driver.surname}`
        driver.gmv_77_formated = this.$options.filters.convertCentsToEurosWithCurrency(driver.stats.gmv_77)
      }

      let driversWithGMV = _.filter(response.data, (driver) => driver.stats.gmv_77_avg !== null)
      this.stats.gmv_77_avg = _.meanBy(driversWithGMV, (driver) => driver.stats.gmv_77_avg);

      this.stats.total_mileage = _.ceil(this.stats.total_mileage, 2)
      this.stats.global_rating = _.ceil((this.stats.global_rating / response.data.length), 2);

      this.stats.accepted_instant_ride_rate = (this.stats.nb_accepted_instant_ride * 100) / this.stats.nb_proposed_instant_ride;
      this.stats.driver_cancel_rate = (this.stats.nb_driver_cancel * 100) / this.stats.nb_proposed_ride;
      this.stats.driver_cancel_rate = this.stats.driver_cancel_rate ? this.stats.driver_cancel_rate : 0;
      this.stats.accepted_instant_ride_rate = this.stats.accepted_instant_ride_rate ? this.stats.accepted_instant_ride_rate : 0;

      let driversWithEvaluateScore = _.filter(response.data, (driver) => driver.stats.evaluate_score)
      this.stats.evaluate_score = this.stats.evaluate_score / driversWithEvaluateScore.length;

      // TODO: REPLACE WITH ENUM
      this.nbDriverAvailable = _.filter(response.data, (o) => o["online"] === 1).length
      this.nbDriverInService = _.filter(response.data, (o) => o["online"] === 4).length
      this.nbDriverConnected = this.nbDriverAvailable + this.nbDriverInService
      this.nbDriverInBookedOnly = _.filter(response.data, (o) => (o["type_order"] === 2)).length

      this.drivers = response.data
    }).catch((reason) => {
      this.isLoading = false
      console.log(reason)
    })

    const startDate = new Date();
    const endDate = new Date();
    startDate.setUTCHours(0, 0, 0);
    endDate.setUTCHours(0, 0, 0);
    startDate.setDate(startDate.getDate() - 30);
    endDate.setDate(endDate.getDate() - 1);

    axios.get(
      `/subcontractors/${this.$route.params.subcontractorId}/history?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`).then((response) => {
     // let activeDrivers = _.filter(response.data, (driver) => (driver["driver_status"] === 12));
     // let statsGroupedByDate = _(response.data).flatMap('stats').sortBy('statistics_date').groupBy('statistics_date').value();

      this.clearDriversStatsHistory()
      this.updateDriversStatsHistoryChart(response.data)
      this.updateDriversStatsHistoryCSV(response.data)
    }).catch((reason) => {
      console.log(reason)
    })
  },
  data () {
    return {
      isLoading: true,
      drivers: [],
      stats: {
        gmv: 0,
        gmv_77: 0,
        gmv_avg: 0,
        gmv_77_avg: 0,
        nb_ride_total: 0,
        total_mileage: 0,
        evaluate_score: 0,
        nb_proposed_instant_ride: 0,
        nb_accepted_instant_ride: 0,
        accepted_instant_ride_rate: 0,
        nb_cancelled_instant_ride: 0,
        nb_instant_ride_done: 0,
        nb_prebooked_ride_done: 0,
        nb_ride_done: 0,
        driver_cancel_rate: 0,
        nb_driver_cancel: 0,
        nb_cancel: 0,
        time_online: 0,
        time_in_ride: 0,
        statsHistory: [],
      },
      nbDriverInBookedOnly: 0,
      nbDriverInService: 0,
      nbDriverConnected: 0,
      driversFields: [
        { key: 'avatar', filter: false, _classes: 'text-center', _style: 'width:80px' },
        { key: 'fullName', label: "Nom", sorter: false },
        { key: 'gmv_77_formated', label: 'Revenu Net', sorter: true, filter: false, _classes: "text-center", _style: 'width:100px', },
        { key: 'nb_ride_total', _classes: "text-center", _style: 'width:100px', label: "Courses", filter: false, sorter: true },
        { key: 'nb_driver_cancel', _classes: "text-center", _style: 'width:150px', label: "Annulations Chauffeur", filter: false, sorter: true },
        { key: 'type_order', _classes: "text-center", label: "Mode", _style: 'width:100px', filter: false }
      ],
      history: {
        csvArray: [],
        ridesChartValues: [],
        ridesChartLabels: [],
        ridesChartOptions: {
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        },
        acceptanceRateChartValues: [],
        acceptanceRateChartLabels: [],
        acceptanceRateChartOptions: {
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      }
    }
  },
  methods: {
    rowClicked: function(driver) {
      this.$router.push(`${this.$route.params.subcontractorId}/drivers/${driver.driver_no}`);
    },
    clearDriversStatsHistory() {
      this.history.csvData = null
      this.history.csvFilename = ""
      this.history.ridesChartLabels = []
      this.history.ridesChartValues = []
    },
    updateDriversStatsHistoryChart(history) {

      let statsGroupedByDate = _.groupBy(history, 'statistics_date')

      let instantRideSuccessHistory = { label: 'Courses instantanéees', key: 'real_success_nb', borderColor: 'green', data: [] }
      let subscribeRideSuccessHistory = { label: 'Réservations', key: 'subscribe_success_nb', borderColor: 'blue', data: [] }

      statsGroupedByDate = Object.keys(statsGroupedByDate).sort().reduce((obj, key) => {
          obj[key] = statsGroupedByDate[key];
          return obj;
        },
        {}
      );

      for (const [date, values] of Object.entries(statsGroupedByDate)) {
        //const dateFormated = this.$options.filters.getShortMonthDayStringForDate(date)
        this.history.ridesChartLabels.push(date);

        instantRideSuccessHistory.data.push(_.sumBy(values, instantRideSuccessHistory.key));
        subscribeRideSuccessHistory.data.push(_.sumBy(values, subscribeRideSuccessHistory.key));
      }

      this.history.ridesChartValues.push(instantRideSuccessHistory)
      this.history.ridesChartValues.push(subscribeRideSuccessHistory)
    },
    updateDriversStatsHistoryCSV(drivers) {

      let statsGroupedByDriver = _.groupBy(drivers, 'driver_no')
      const startDate = new Date();
      const endDate = new Date();
      startDate.setUTCHours(0, 0, 0);
      endDate.setUTCHours(0, 0, 0);
      startDate.setDate(startDate.getDate() - 30);
      endDate.setDate(endDate.getDate() - 1);

      let reportCSV = [];
      for (const [key, driver] of Object.entries(statsGroupedByDriver)) {

        let {
          driver_no: driverNo,
          name: driverFirstname,
          surname: driverLastname
        } = _.find(driver, 'driver_no');

        driverFirstname = _.startCase(driverFirstname)
        driverLastname = _.upperCase(driverLastname)

        let realAcceptanceRateForPeriod = ((_.sumBy(driver, "real_accepted_nb") / _.sumBy(driver, "real_dispatch_received_nb")).toFixed(2)) * 100
        let realCancelRateForPeriod = ((_.sumBy(driver, "real_driver_cancel_nb") / _.sumBy(driver, "real_accepted_nb")).toFixed(2)) * 100
        realAcceptanceRateForPeriod = isNaN(realAcceptanceRateForPeriod) ? 0 : realAcceptanceRateForPeriod
        realCancelRateForPeriod = isNaN(realCancelRateForPeriod) ? 0 : realCancelRateForPeriod

        let driverRateForPeriod = _.mean(_.compact(_.map(driver, "service_score"))).toFixed(2)
        driverRateForPeriod = isNaN(driverRateForPeriod) ? 0 : driverRateForPeriod

        let row = [
          ["ID Chauffeur", "Chauffeur", "Données", ..._.map(driver, "statistics_date"), "Total"],
          [driverNo, `${driverFirstname} ${driverLastname}`, "GMV", ..._.map(driver, (d) => this.$options.filters.convertCentsToAmount(d.clear_fee)), this.$options.filters.convertCentsToAmount(_.sumBy(driver, "clear_fee"))],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Temps de connexion (h)", ..._.map(driver, (d) => this.$options.filters.secondToHours(d.online_time)), this.$options.filters.secondToHours(_.sumBy(driver, "online_time"))],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Taux d'acceptation", ..._.map(driver, (d) => this.$options.filters.toPercent(d.real_accepted_rate)), `${realAcceptanceRateForPeriod} %`],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Taux d'annulation", ..._.map(driver, (d) => this.$options.filters.toPercent(d.real_driver_cancel_rate)), `${realCancelRateForPeriod} %`],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Note du jour", ..._.map(driver, "service_score"), driverRateForPeriod],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates proposées", ..._.map(driver, "real_dispatch_received_nb"), _.sumBy(driver, "real_dispatch_received_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates acceptées", ..._.map(driver, "real_accepted_nb"), _.sumBy(driver, "real_accepted_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates réalisées", ..._.map(driver, "real_success_nb"), _.sumBy(driver, "real_success_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses Réservations réalisées", ..._.map(driver, "subscribe_success_nb"), _.sumBy(driver, "subscribe_success_nb")]
        ]

        reportCSV = [...reportCSV, ...row]
      }
      this.history.csvData = reportCSV

      this.history.csvFilename = `history_${startDate.toISOString()}_${endDate.toISOString()}`
    },

    getOnlineStatus(value) {
      let color
      // info
      if (value === 1 || value === 2) color = 'success'
      else if (value === 3) color = 'danger'
      else if (value === 4) color = 'warning'
      else color = 'secondary'

      return color
    },

    getRowStatusFor(value) {
      let _class = null;
      if (value > 3) _class = "table-danger"
      return _class
    },

    typeOrderFormat(value) {
      let typeOrder

      if (value === 1) typeOrder = 'ALL'
      else if (value === 2) typeOrder = 'BOOKED_ONLY'
      else if (value === 3) typeOrder = 'REALTIME_ONLY'
      else typeOrder = null

      return typeOrder
    }
  }
}
</script>
