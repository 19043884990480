<template>
  <CButton color="primary" class="float-right" v-on:click="buttonClicked" v-if="data.length">
    Telecharger les données en csv
    <CIcon name="cil-cloud-download"/>
  </CButton>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    "data": Array,
    "format": String,
  },
  methods: {
    buttonClicked() {
      switch (this.format) {
        case "csv":
          this.downloadInCSV();
          break;
        default:
          throw new Error("Cannot find format")
      }
    },

    downloadInCSV() {
      let csv = this.formatArrayToCSV(this.data);

      let hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'export.csv';
      hiddenElement.click();
    },

    formatArrayToCSV(array) {
      let csv = 'sep=;\n';

      array.forEach((row) => {
        csv += row.join(';');
        csv += "\n";
      });

      return csv
    }
  }
}
</script>
